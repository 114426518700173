import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(private _router: Router) {
    window.addEventListener('storage', this.storageEventHandler.bind(this));
  }

  storageEventHandler(evt) {
    if (evt.key === 'SAMLLoginStamp') {
      window.location.reload();
    } else if (evt.key === 'ExternalFrameLoginInitiate' && this._router.url === '/login') {
      window.location.reload();
    } else if (evt.key === 'ForceLogoutRefresh' && this._router.url !== '/login') {
      window.location.reload();
    }
  }

  ngOnInit(): void {
    let routeParams: string;
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && routeParams) {
        window.history.replaceState({}, '', `${location.pathname}?${routeParams}`);
      } else if (event instanceof NavigationStart) {
        const params = event.url.split('?');
        if (params && params.length >= 2) {
          routeParams = params[1];
        }
      }
    });
  }
}

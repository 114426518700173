import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ERROR_CODE, getUrlParameterByName } from '../../api/HelperFunctions';
import { DaVinciApp } from '../../model/Plugin';
import { PLUGIN_POSITION } from '../../model/PluginPosition';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggerService } from '../../services/logger.service';
import { ConfigurationService } from '../../services/configuration.service';
import { IConfiguration } from '../../model/IConfiguration';
import { OFHelper } from '../../services/OFHelper';
import { MatDialog } from '@angular/material/dialog';
import { INotificationMessage } from '../../api/AmcApi';
import { AppsService } from '../../services/apps.service';
import { ContextualContactsService } from '../../services/contextual-contacts.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { StorageService } from 'src/app/storage.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  panelSize = 350;
  private apiUrl: string;
  private config: IConfiguration;
  plugins: {
    [layer: number]: {
      [pluginPosition: string]: DaVinciApp[];
    };
  };

  pluginSources: { [name: string]: SafeResourceUrl } = {};
  appsLoaded: boolean;
  appsLoadedTimeout: number;
  appLogoUrl: string;
  showReload = false;
  loggingOut = false;
  layerToGrayOutBelow: number;

  profileSelected = false;
  profiles: any = [];
  profileID: string;
  logoName: string;
  notificationMessages: INotificationMessage[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private loggerService: LoggerService,
    private appsService: AppsService,
    public dialog: MatDialog,
    private contextualContactsService: ContextualContactsService,
    private _OFHelper: OFHelper,
    private apiService: ApiService,
    private ps: NgxPermissionsService,
    private _router: Router
  ) {
    this.config = configurationService.config;
    this.plugins = {};
    this._OFHelper.layerToGrayOutBelow$.subscribe((layer) => (this.layerToGrayOutBelow = layer));
    this.logoName = StorageService.getLogoPath();
    this.appsLoaded = false;
    this._OFHelper.appsLoaded.subscribe((areAppsLoaded) => {
      this.appsLoaded = areAppsLoaded;
      if (this.appsLoaded === true) {
        window.clearTimeout(this.appsLoadedTimeout);
      }
    });
    OFHelper.notificationMessages$.subscribe((value) => {
      this.notificationMessages = value;

      this.loggerService.logger.logDebug(`notification messages set to: [ ${value.toString()}]`, ERROR_CODE.Other);
    });
    this._OFHelper.globalConfiguration$.subscribe((globalConfiguration) => {
      this.appsLoadedTimeout = window.setTimeout(() => {
        this.showReload = true;
      }, globalConfiguration.MaxAppLoadTime);
      this.appLogoUrl = globalConfiguration.AppLogoUrl;
    });
    this._OFHelper.loggingOut$.subscribe((isLoggingOut) => {
      this.loggingOut = isLoggingOut;

      if (isLoggingOut && apiService.backgroundTokenRenewTimer !== null) {
        clearInterval(apiService.backgroundTokenRenewTimer);
        apiService.backgroundTokenRenewTimer = null;
      }
    });
  }

  async logout(reason?: string) {
    await this.apiService.logout(reason);
    window.location.reload();
  }

  // Start loading configs for selected profile
  async setProfile(profileID: string) {
    this.profileID = profileID;
    window.localStorage.setItem('profileID', profileID);
    if (getUrlParameterByName('disabled')) {
      $('#phoneMainContainerId').addClass('disabled');
      $('#PluginContainer').addClass('disabled');
      $('#PluginContainer').css('display', 'none');
      await this._OFHelper.loadPlugins(true, this.config.apiUrl, this.profileID).then(() => {
        for (const pluginName in OFHelper.plugins) {
          if (!(OFHelper.plugins[pluginName].config.variables['Keep enabled when toolbar is disabled'] === true)) {
            $(OFHelper.plugins[pluginName].iframe).addClass('disabled');
          }
        }
      });
    } else {
      await this._OFHelper.loadPlugins(false, this.config.apiUrl, this.profileID);
    }
    Object.values<DaVinciApp>(OFHelper.plugins).forEach((plugin) => {
      // populate map/array if not yet defined
      if (this.plugins[plugin.layer] == null) {
        this.plugins[plugin.layer] = {};
        Object.values(PLUGIN_POSITION).forEach((position) => (this.plugins[plugin.layer][position] = []));
      }

      this.plugins[plugin.layer][plugin.position].push(plugin);
      this.pluginSources[plugin.name] = this.getPluginSrc(plugin);
    });
    const userName = this.getUserName();
    const profile = this.profiles.filter((profile) => profile.profileid === profileID);
    const profileName = profile[0].profileName;
    this.loggerService.logger.logInformation('ContactCanvasFramework - Home : Profile selected: ' + profileName + ' User: ' + userName);
    this.plugins = { ...this.plugins };
    this.profileSelected = true;
  }

  async ngOnInit() {
    try {
      window.addEventListener('message', this._OFHelper.postMessageListener);
      this.profiles = await this.apiService.getUserProfiles();

      const profileName = getUrlParameterByName('profile');

      // Convert profile name to profile ID
      if (profileName) {
        this.profileID = this.profiles.filter((profile) => profile.profileName === profileName)[0].profileid;
      }

      // If no profile is given in query parameters, check session storage
      if (!this.profileID) {
        this.profileID = window.localStorage.getItem('profileID');
      }

      // If profile ID not in session storage...
      if (!this.profileID) {
        // Automatically select profile if there is only one
        if (this.profiles.length === 1) {
          this.setProfile(this.profiles[0].profileid);
          window.localStorage.setItem('profileID', this.profiles[0].profileid);
        }
      } else {
        const profile = this.profiles.filter((profile) => profile.profileid === this.profileID);
        const profilename = profile[0].profileName;
        const userName = this.getUserName();
        this.loggerService.logger.logInformation('ContactCanvasFramework - Home : Profile selected: ' + profilename + ' User: ' + userName);
        // Set profile and start loading config
        this.profileSelected = true;
        this.setProfile(this.profileID);
        window.localStorage.setItem('profileID', this.profileID);
      }

      this.removePreLoginScript();
    } catch (e) {
      this.loggerService.logger.logError(`HomeComponent - ngOnInit error: ${JSON.stringify(e)}`);
    }
  }

  getUserName(): string {
    return this.apiService.getUserName();
  }

  removePreLoginScript() {
    try {
      const me = Array.from(document.querySelectorAll('script[data-name="preloginscript"]'));
      me.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    } catch (e) {
      this.loggerService.logger.logError('Error removing prelogin script operation! Error:' + e.message);
    }
  }

  showNotifications() {
    this.loggerService.logger.logDebug('start: showNotifications', ERROR_CODE.Other);

    this.loggerService.logger.logDebug('end: showNotifications', ERROR_CODE.Other);
  }

  reloadClicked() {
    window.location.reload();
  }

  getCRMPanelPlugins(layer: number) {
    return this.plugins[layer][PLUGIN_POSITION.crm_panel];
  }

  getPluginSrc(plugin: DaVinciApp) {
    // NOTE: possible XSS by using bypass security
    return this.sanitizer.bypassSecurityTrustResourceUrl(plugin.src);
  }
}
